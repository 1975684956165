import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../../../environments/environment";
import { get } from 'scriptjs'
import { GetUpgCallbackStatus } from "src/app/dashboard/seller/financials/services/get-upg-callback-status.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { UpGrequestToPay } from "src/app/dashboard/seller/financials/services/upg-request-to-pay.service";
import Swal from "sweetalert2";
import { FormGroup } from '@angular/forms';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { Validators } from 'src/app/shared/validators';

declare var Lightbox: any;

@Component({
  selector: 'app-light-box-upg',
  templateUrl: './light-box-upg.component.html',
  styleUrls: ['./light-box-upg.component.scss'],
})
export class LightBoxUpgComponent implements OnInit {
  orderId: string = '';
  paymentMethodFromLightBox: number = 2; // default payment method will be all
  amount: number = 0;
  mID: number = 10940538636;
  tID: number = 47778387;
  secureHash: string = '';
  trxDateTime: string = '';
  returnUrl: string = '/';
  MerchantReference: any;
  processOneTime: boolean = false;
  SystemReference: string | null;
  qrcode: string | null;

  constructor(
    private router: Router,
    private getUpgCallbackStatus: GetUpgCallbackStatus,
    private dialog: MatDialog,
  ) {
    this.SystemReference = null;
  }

  ngOnInit() {}
  /**
   * include upg script on component init
   * @param url
   */

  callLightBox() {
    this.dialog.open(QRCodePopup, {
      data: {
        qrcodeData: this.qrcode,
        MerchantReference: this.MerchantReference,
        SystemReference: this.SystemReference,
      },
    });
  }

  openPaySky() {
    get(environment.PaySky_LIGHTBOX, () => {
      Lightbox.Checkout.configure = {
        OrderId: '',
        MID: this.mID,
        TID: this.tID,
        SecureHash: this.secureHash,
        TrxDateTime: this.trxDateTime,
        AmountTrxn: this.amount,
        MerchantReference: this.MerchantReference,
        ReturnUrl: this.returnUrl,
        completeCallback: async (data: any) => {
          console.log('openPaySky data: ', data);
          this.SystemReference = data.SystemReference;
          await this.redirectToGatePage(data);
        },
        cancelCallback: () => {
          this.router.navigate(['/gate'], {
            queryParams: {
              refNum: this.SystemReference
                ? this.SystemReference
                : this.orderId,
            },
          });
        },
      };
      Lightbox.Checkout.showLightbox();
    });
  }

  validateUPGRequiredData(): boolean {
    const requiredProperties = [
      'tID',
      'paymentMethodFromLightBox',
      'mID',
      'amount',
      'trxDateTime',
      'MerchantReference',
    ];
    for (const requiredProperty of requiredProperties) {
      if (this[requiredProperty] == null) return false;
    }
    return true;
  }

  async redirectToGatePage(data: {
    MerchantReference: string | number;
    SystemReference: string | number;
    status: string;
  }) {
    const dataToSend = {
      merchantReference: data.MerchantReference,
      systemReference: data.SystemReference,
      status: data.status,
    };

    try {
      if (!data) throw new Error('completeCallback: returning data in empty');

      this.getUpgCallbackStatus.setParams(dataToSend);
      const pullUpgCallbackStatus = await this.getUpgCallbackStatus.make();
      if (!pullUpgCallbackStatus.success) throw new Error('Payment Failed');
    } catch (e) {
      console.log('completeCallback: ', e);
    } finally {
      this.router.navigate(['/gate'], {
        queryParams: {
          refNum: data.SystemReference ? data.SystemReference : this.orderId,
        },
      });
    }
  }
}

@Component({
  selector: 'app-qrcodepopup',
  templateUrl: './qrcodepopup.component.html',
})
export class QRCodePopup implements OnInit {
  public qrcodeData: string;
  public requestToPayForm: FormGroup;

  private MerchantReference: string;
  private SystemReference: number;
  private refNum: string | number;
  private popupClosed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<QRCodePopup>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      qrcodeData: string;
      SystemReference: number;
      MerchantReference: string;
    },
    private ugpRequestToPay: UpGrequestToPay,
    private router: Router,
    private getUpgCallbackStatus: GetUpgCallbackStatus,
    public formValidator: FormValidatorService,
  ) {
    this.qrcodeData = data.qrcodeData;
    this.MerchantReference = data.MerchantReference;
    this.SystemReference = data.SystemReference;
    this.refNum = data.MerchantReference;
  }

  async ngOnInit() {
    this.requestToPayForm = this.formValidator.createForm([
      {
        fieldName: 'phone',
        defaultValue: null,
        validators: [
          {
            validatorFn: CustomValidators.customPattern(/(^[0-9\+]{11,11}$)/),
            message: 'Not a valid phone number'
          }
        ]
      },
    ]);

    await this.redirectToGatePage({
      MerchantReference: this.MerchantReference,
      SystemReference: this.SystemReference,
      status: 'Paid',
    });
  }

  onClose() {
    this.dialogRef.close();
    this.popupClosed = true;
  }

  async requestToPay() {
    try {
      const MobileNumber = this.requestToPayForm.get('phone').value;
      if (!MobileNumber) {
        this.formValidator.formErrors.phone = `Phone number is Required`;
        return;
      }
      const data = {
        MerchantReference: this.MerchantReference,
        SystemReference: this.SystemReference,
        ISOQR: this.qrcodeData,
        MobileNumber,
      };
      this.ugpRequestToPay.setParams(data);
      const response = await this.ugpRequestToPay.make();
      if (response.success) {
        return Swal.showValidationMessage(
          ` The Request has been sent to your Wallet`,
        );
      }
    } catch (error) {
      Swal.showValidationMessage(` Request failed: ${error} `);
    }
  }

  private async redirectToGatePage(data: {
    MerchantReference: string | number;
    SystemReference: string | number;
    status: string;
  }) {
    const dataToSend = {
      merchantReference: data.MerchantReference,
      systemReference: data.SystemReference,
      status: data.status,
    };

    try {
      if (!data) throw new Error('completeCallback: returning data in empty');

      this.getUpgCallbackStatus.setParams(dataToSend);
      this.getUpgCallbackStatus.deactivateLoader();
      const pullUpgCallbackStatus = await this.getUpgCallbackStatus.make();
      if (pullUpgCallbackStatus.success) {
        this.refNum = this.SystemReference;
      }
    } catch (e) {
      console.log('completeCallback: ', e);
    } finally {
      if (this.popupClosed) return;
      this.onClose();
      this.router.navigate(['/gate'], {
        queryParams: {
          refNum: this.refNum,
        },
      });
    }
  }
}
